import ComponentAccordion from "./accordion.js";
import ComponentParagraph from "./paragraph.js";
import ComponentParagraphImage from "./paragraph-image.js";
import ComponentQuote from "./quote.js";
import ComponentDescriptionList from "./description-list.js";
import ComponentBenefitTeaser from "./benefit-teaser.js";
import ComponentProductTabs from "./product-tabs.js";
import ComponentContentTabs from "./content-tabs.js";
import ComponentLogoGrid from "./logo-grid.js";
import ComponentHubspotForm from "./hubspot-form.js";

const Components = ({ content }) => {
  return (
    <>
      {content.content.map((component, index) => {
        if (!component?.__component) return null;
        const uniqueKey = `component_${index}`;
        switch (component.__component) {
          case "content.product-accordion":
            return (
              <ComponentProductTabs
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.paragraph":
            return (
              <ComponentParagraph
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.content50":
            return (
              <ComponentParagraphImage
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.col50-multi":
            return (
              <ComponentContentTabs
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.faq":
            return (
              <ComponentAccordion
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.benefit":
            return (
              <ComponentBenefitTeaser
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.testimonial":
            return (
              <ComponentQuote
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.prop-group":
            return (
              <ComponentDescriptionList
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.logo-grid":
            return (
              <ComponentLogoGrid
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          case "content.hub-spot-form":
            return (
              <ComponentHubspotForm
                data={component}
                key={uniqueKey}
                uniqueKey={uniqueKey}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default Components;
