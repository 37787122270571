const ComponentParagraph = ({ uniqueKey, data }) => {
  // Layout properties
  const layout = data.layout || {};
  const reverse = layout.reverse || false;
  const layoutType = layout.layout || "";

  return (
    <div
      key={`component_${uniqueKey}`}
      className="component component-paragraph"
      reversed={reverse}
      layout={layoutType}
    >
      <p dangerouslySetInnerHTML={{ __html: data.text }} />
    </div>
  );
};

export default ComponentParagraph;
