import config from "../config";
import Link from "./link";

const ComponentHeroHomepage = ({ uniqueKey, data }) => {
  // CTA properties
  const cta = data.CTA || {};
  const ctaLabel = cta.label || "";
  const externalURL = cta.url;
  const preferURL = cta.preferURL || false;
  const slug = cta.page?.data?.attributes?.slug || "";

  const ctaHref = preferURL && externalURL ? externalURL : `/${slug}`;
  const isExternal = preferURL && externalURL;

  return (
    <>
      <div
        key={`component_${uniqueKey}`}
        className="component component-hero component-hero--homepage"
      >
        <h1>{data.header}</h1>
        <div>
          <p>{data.claim}</p>
          {ctaLabel && (
            <Link href={ctaHref} isExternal={isExternal} content={ctaLabel} />
          )}
        </div>
      </div>
      <video
        className="component-hero__video"
        width="320"
        height="240"
        loop
        autoPlay
        muted
        playsInline
      >
        <source
          src={`${config.apiBaseUrl}${data.video.data.attributes.url}`}
          type="video/mp4"
        />
      </video>
    </>
  );
};

export default ComponentHeroHomepage;
