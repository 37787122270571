import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../LanguageContext";

const NavHeader = ({ options }) => {
  const mainNavigation = options?.data?.attributes?.mainNavigation;

  const { currentLocale } = useLanguage();
  const [toggle, setToggle] = useState(true);

  function NavLinks(url, title) {
    const itemUrl = new URL(url);
    const relativeUrl = itemUrl.pathname;
    return (
      <NavLink
        to={relativeUrl}
        className="nav-link"
        onClick={() => setToggle(!toggle)}
      >
        {title}
      </NavLink>
    );
  }

  return (
    <header>
      <NavLink
        to={`/${currentLocale}`}
        className="logo"
        onClick={() => setToggle(!toggle)}
      >
        metrilus
      </NavLink>
      <button
        type="button"
        className="menu-btn"
        aria-expanded={!toggle}
        aria-controls="menu"
        onClick={() => setToggle(!toggle)}
      >
        <span className="menu-btn__label">Menu</span>
        <span className="menu-btn__icon">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </span>
      </button>
      <nav id="menu" className={!toggle ? "expanded" : ""} role="navigation">
        <ul>
          {mainNavigation?.data?.attributes?.pages?.data.map((item, index) => (
            <li key={`nav-link_${index}`}>
              {NavLinks(
                `${window.location.origin}/${item?.attributes?.slug}`,
                item?.attributes?.internalTitle
              )}
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default NavHeader;
